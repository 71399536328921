import { ICompany } from './ICompany';
import { INumber } from './INumber';
import { IUser } from './IUser';

export interface ILevel {
    id: number;
    name: string;
    isActive: boolean;
    company: ICompany;
    migrated: boolean;
    root: boolean;
    oldId: number;
    users: IUser[];
    numbers: INumber[];
    createdBy: number;
    updatedBy: number | null;
    createdAt: string;
    updatedAt: string;
    createdByUser?: IUser;
    updatedByUser?: IUser;
    settings: ISetting[];
}

interface ISetting {
    configuration: string;
    value: string;
}

export enum LevelSettingDefaults {
    CALLER_IDENTIFICATION = '551143808171',
    HOST_DEVICE = '34.151.254.224',
    DTLS_CERT_FILE = '/etc/asterisk/asterisk.cert',
    DTLS_PRIVATE_KEY = '/etc/asterisk/asterisk.key',
    CONTEXT = 'to-output',
}

export const LevelSettingDefaultsMap: Record<
    LevelSettingDefaults,
    { setting: string; value: string }
> = {
    [LevelSettingDefaults.CALLER_IDENTIFICATION]: {
        setting: 'CALLER_IDENTIFICATION',
        value: LevelSettingDefaults.CALLER_IDENTIFICATION,
    },
    [LevelSettingDefaults.HOST_DEVICE]: {
        setting: 'HOST_DEVICE',
        value: LevelSettingDefaults.HOST_DEVICE,
    },
    [LevelSettingDefaults.DTLS_CERT_FILE]: {
        setting: 'DTLS_CERT_FILE',
        value: LevelSettingDefaults.DTLS_CERT_FILE,
    },
    [LevelSettingDefaults.DTLS_PRIVATE_KEY]: {
        setting: 'DTLS_PRIVATE_KEY',
        value: LevelSettingDefaults.DTLS_PRIVATE_KEY,
    },
    [LevelSettingDefaults.CONTEXT]: {
        setting: 'CONTEXT',
        value: LevelSettingDefaults.CONTEXT,
    },
};

export const getLevelSettingDefault = (key: LevelSettingDefaults) => LevelSettingDefaultsMap[key];

export enum MEETING {
    MEETING_PRODUCT = 'MEETING_PRODUCT',
}
